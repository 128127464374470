class BannerWithPinsUnfolder {
  constructor(el) {
    // Store the <details> element
    this.el = el;
    // Store the <summary> element
    this.summary = el.querySelector('summary');
    // Store the <div class="content"> element
    this.content = el.querySelector('.pins__wrapper');

    // Store the animation object (so we can cancel it if needed)
    this.animation = null;
    // Store if the element is closing
    this.isClosing = false;
    // Store if the element is expanding
    this.isExpanding = false;
    // Detect user clicks on the summary element
    this.summary.addEventListener('click', (e) => this.onClick(e));

    // If device is mobile and always show products on mobile is enabled then this.showProductsAlwaysOnMobileDevice = true
    this.showProductsAlwaysOnMobileDevice = this.el.hasAttribute('data-always-show-products-on-mobile') && window.innerWidth < 768;
    if (this.showProductsAlwaysOnMobileDevice) {
      // Open the details as default
      this.el.open = true;
    }
  }

  onClick(e) {
    // Stop default behaviour from the browser
    e.preventDefault();

    // If device is mobile and always show products on mobile is enabled, then don´t animate (run the rest of the function)
    if (this.showProductsAlwaysOnMobileDevice) {
      return;
    }

    // Add an overflow on the <details> to avoid content overflowing
    this.el.style.overflow = 'hidden';
    // Check if the element is being closed or is already closed
    if (this.isClosing || !this.el.open) {
      // this.open();
      this.el.open = true;
      if (window.innerWidth >= 768) {
        // if desktop
        this.pinsAnimation('translateX(100%)', 'translateX(0)');
      } else {
        // if mobile
        this.pinsAnimation('translateY(-100%)', 'translateY(0)');
      }
      this.el.classList.add('pins-animate');
      // Check if the element is being openned or is already open
    } else if (this.isExpanding || this.el.open) {
      if (window.innerWidth >= 768) {
        // if desktop
        this.pinsAnimation('translateX(0)', 'translateX(100%)');
      } else {
        // if mobile
        this.pinsAnimation('translateY(0)', 'translateY(-100%)');
      }

      // When the animation is complete, call onAnimationFinish()
      this.animation.onfinish = () => this.onAnimationFinish(false);
      this.el.classList.remove('pins-animate');
    }
  }

  pinsAnimation(tranformStart, transformEnd) {
    // Start a WAAPI animation
    this.animation = this.content.querySelector('.pins__wrapper--inner').animate(
      {
        // Set the keyframes from the startWidht to endWidth
        transform: [tranformStart, transformEnd],
      },
      {
        duration: 400,
        easing: 'ease-out',
      },
    );
  }

  onAnimationFinish(open) {
    // Set the open attribute based on the parameter
    this.el.open = open;
    // Clear the stored animation
    this.animation = null;
    // Reset isClosing & isExpanding
    this.isClosing = false;
    this.isExpanding = false;
    // Remove the overflow hidden and the fixed widht
    this.el.style.widht = this.el.style.overflow = '';
  }
}

document.querySelectorAll('.banner-with-pins').forEach((el) => {
  new BannerWithPinsUnfolder(el);
});
